// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { useSelfStore } from '@/store/self'
import { useApiStore } from '@/store/api'
import * as Sentry from "@sentry/browser";
import { Google } from '@/utils/tracking';
import DefaultLayout from '@/layouts/DefaultLayout.vue'

// https://itnext.io/3-ways-to-create-a-multiple-layouts-system-vue-3-b24c0736b7bb

// nested routes
// https://router.vuejs.org/guide/essentials/nested-routes
// tabs with routes
// https://jsfiddle.net/thorne51/9g2zeow1/2/

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/home/HomeDashboard.vue')
	},
	{
		path: '/apps',
		name: 'apps-list',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/apps/AppsList.vue'),
	},
	{
		path: '/apps/:id',
		name: 'apps-edit',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/apps/AppsEdit.vue'),
	},
	{
		path: '/apps/:appId/connections/:id',
		name: 'connections-edit',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/connections/ConnectionsEdit.vue'),
	},
	{
		path: '/workflows',
		name: 'workflows-list',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/workflows/WorkflowsList.vue'),
	},
	{
		path: '/apps/:appId/workflows/:id',
		props: true,
		name: 'workflows-edit',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/workflows/WorkflowsEdit.vue'),
	},
	{
		path: '/errors',
		name: 'errors-list',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/errors/ErrorsList.vue'),
	},
	{
		path: '/errors/:id',
		name: 'errors-edit',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/errors/ErrorsEdit.vue'),
	},
	{
		path: '/platforms',
		name: 'platforms-list',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/platforms/PlatformsList.vue'),
	},
	{
		path: '/platforms/:platformId',
		name: 'platforms-edit',
		props: true,
		component: () => import('@/components/platforms/PlatformsEdit.vue'),
		children: [{
			path: 'general',
			name: 'platforms-edit-general',
			props: true,
			component: () => import('@/components/platforms/components/General.vue'),
		}, {
			path: 'authentication',
			props: true,
			component: () => import('@/components/platforms/components/Authentication.vue'),
		}, {
			path: 'actions',
			props: true,
			component: () => import('@/components/platforms/components/Actions.vue'),
		}, {
			path: 'actions/:actionId',
			props: true,
			name: 'platforms-actions-edit',
			component: () => import('@/components/platforms/subcomponents/ActionsTabs.vue'),
			children: [{
				path: 'settings',
				props: true,
				name: 'platforms-actions-edit-settings',
				component: () => import('@/components/platforms/subcomponents/Settings.vue'),
			}, {
				path: 'inputs',
				props: true,
				name: 'platforms-actions-edit-inputs',
				component: () => import('@/components/platforms/subcomponents/Inputs.vue'),
				children: [{
					path: '',
					props: true,
					name: 'platforms-actions-edit-inputs-field-list',
					component: () => import('@/components/platforms/sub-subcomponents/InputFieldsList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: 'platforms-actions-edit-inputs-field-edit',
					component: () => import('@/components/platforms/sub-subcomponents/InputFieldEdit.vue'),
				}]
			},{
				path: 'outputs',
				props: true,
				name: 'platforms-actions-edit-outputs',
				component: () => import('@/components/platforms/subcomponents/Outputs.vue'),
				children: [{
					path: '',
					props: true,
					name: 'platforms-actions-edit-outputs-field-list',
					component: () => import('@/components/platforms/sub-subcomponents/OutputFieldsList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: 'platforms-actions-edit-outputs-field-edit',
					component: () => import('@/components/platforms/sub-subcomponents/OutputFieldEdit.vue'),
				}]
			}, {
				path: 'api',
				props: true,
				name: 'platforms-actions-api',
				component: () => import('@/components/platforms/subcomponents/Api.vue'),
			}, {
				path: 'outputs',
				props: true,
				name: 'platforms-actions-outputs',
				component: () => import('@/components/platforms/subcomponents/Outputs.vue'),
			}]
		}, {
			path: 'triggers',
			props: true,
			component: () => import('@/components/platforms/components/Triggers.vue'),
		}, {
			path: 'tenants',
			props: true,
			component: () => import('@/components/platforms/components/Tenants.vue'),
		}]
	},
	{
		path: '/settings',
		name: 'settings',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/settings/SettingsEdit.vue'),
	},
	{
		path: '/callback',
		redirect: (to: any) => {
			return { path: '/' }
		}
	},
	{
		path: '/token',
		// somewhat unnecessary, we don't actually render this, we just
		// catch it in the guard below
		component: () => import('@/views/Unauthorized.vue')
	},
	{
		path: '/logout',
		name: 'logout',
		redirect: (to: any) => {
			return { path: '/' }
		}
	},
	{
		path: '/unauthorized',
		name: 'unauthorized',
		component: () => import('@/views/Unauthorized.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	strict: true,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0 }
		}
	}
})

router.beforeEach(async (to, from) => {
	const selfStore = useSelfStore();
	const apiStore = useApiStore();
	if (!selfStore.isUserSet()) {
		if (to.path == '/token') {
			// TODO: store the token in cache? what's our expiration?
			apiStore.setToken(JSON.parse(to.query.tokenJson as string))
			await selfStore.refreshUser()
			if (localStorage.getItem('login_redirect')) {
				var value = localStorage.getItem('login_redirect')
				localStorage.removeItem('login_redirect')
				window.location.href = value!
			} else {
				return { name: 'home' }
			}
		} else if (apiStore.getToken()) {
			// got a token; if it's expired, we'll get a 401 and redirect to /token automatically
			await selfStore.refreshUser()
			return true
		} else {
			// no token, just go to login
			window.setTimeout(() => {
				window.location.href = import.meta.env.VITE_API_ROOT + '/api/authentication/login'
			}, 1)
			return false
		}
	}
})

router.onError((error, to) => {
	if (
		error?.message?.includes("Failed to fetch dynamically imported module") ||
		error?.message?.includes("Failed to load module script")
	) {
		if (window.location.href.includes('localhost')) {
			console.error('There was an error loading the page.', error);
		} else {
			window.location.reload();
		}
	}
});
export default router
