<script lang="ts" setup>
import { useHelpStore } from '@/store/help'
import { useAttrs, computed } from 'vue'

const attrs = useAttrs()
const props = defineProps({
    helpKey: { type: String, required: true },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})

const rules = [] as Array<any>;
props.validators.forEach((validatorName: String) => {
    switch (validatorName) {
        case 'required':
            rules.push((v: any) => !!v || `${attrs.label} is required.`)
            break;
    }
})


</script>
<template>
    <v-select 
        v-bind="$attrs"
        :rules="rules"
        :menu-icon="''"
        >
        <template #append-inner>
            <v-icon>mdi-menu-down
            </v-icon>
            <v-icon size="large" @click.stop.prevent="showHelp(props.helpKey)" :color="color">
                mdi-help-circle-outline
            </v-icon>
        </template>
    </v-select>
</template>