///authorization/roles
import { defineStore } from 'pinia'
import { useApiStore } from './api'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// each record is title + content; we parse the content as markdown
export interface HelpRecord{
    title: string;
    content: string;
}
// context = visibility + record
export interface HelpContext{
    visible: boolean;
    key: string | null;
    record: HelpRecord
}
// dictionary of records so we can have typed data from the json
interface HelpDictionary {
    [key: string]: HelpRecord;
}

const router = useRouter();

export const useHelpStore = defineStore('helpStore', () =>
{   
    const context = ref({
        visible: false,
        record: {
            title: 'Default',
            content: 'No help available'
        }
    } as HelpContext);

    let lastKey = '' as string | null
    const setContext = (key: string | null) => {
        // avoid flickering by clicking on the same button over and over
        if ((key == lastKey) && context.value.visible) {
            key = null
        }
        lastKey = key;
        if (key) {
            context.value = {
                key: key,
                visible: true,
                record: {
                    title: 'Loading',
                    content: `Please wait while we load the help content...`
                }
            }
            
            const moduleUrl = import.meta.url;
            const baseUrl = new URL('/assets/help/', moduleUrl).href;
            const fileUrl = new URL(key + '.md', baseUrl).href;
            fetch(fileUrl + '?t=' + new Date().getTime())
                .then(response => response.text())
                .then(text => {
                    let parts = text.split('\n')
                    /*
                    The content of the file looks like this. Note that title is on the first line, followed by content.
                    title: Logo URL
                    This URL should
                    support mulitple lines
                    */
                    let title = parts[0].split(':')[1].trim();
                    let content = parts.slice(1).join('\n');
                    // replace all relative URLs with absolute URLs
                    // this is a bit of a hack, but it works; probably should do it when we convert the markdown?
                    // actually probably should convert the markdown here...
                    content.match(/!\[.*\]\((.*?)\)/g)?.forEach((match) => {
                        let m = match.match(/\(.*?\)/);
                        if (m) {
                            let file = m[0].substring(1, m[0].length - 1);
                            let full = new URL(file, baseUrl).href
                            content = content.replace(file, full);
                        }
                    })
                    context.value.record.content = content;
                    context.value.record.title = title;

                }).catch((e) => {
                    console.error('Error loading help content for key ' + key, e)
                    context.value = {
                        key: '',
                        visible: true,
                        record: {
                            title: 'Not Found',
                            content: 'Error loading content: ' + e
                        }
                    }
                })
            
        } else {
            context.value = {
                key: '',
                visible: false,
                record: {
                    title: 'Not Found',
                    content: 'No help available'
                }
            }
        }
    }

    const getContext = () => {
        return context;
    }

    return { setContext, getContext }
});

