/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '../store'
import router from '../router'
import { loadSentry } from './sentry'

// Types
import type { App } from 'vue'

export function registerPlugins (app: App) {
    loadSentry(app, router)
    app
      .use(vuetify)
      .use(router)
      .use(pinia);
}
