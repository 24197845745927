

<script setup lang="ts">
import { watch } from 'vue'
const props = defineProps({
    show: Boolean,
    text: String
})
const emit = defineEmits(['click:close'])

let timeout = 0

// if the text or flag changes, show the alert again; lets it show longer if the text is updated
watch(() => props.text, (value) => {
    console.log('text changed')
    if (value) {
        console.log('showing')
        clearTimeout(timeout)
        timeout = window.setTimeout(() => {
            emit('click:close')
        }, 3000)
    }
})
watch(() => props.show, (value) => {
    console.log('watch')
    if (value) {
        console.log('showing')
        clearTimeout(timeout)
        timeout = window.setTimeout(() => {
            emit('click:close')
        }, 3000)
    }
})
</script>
<template>
    <transition name="fade">

        <v-alert 
            type="success" 
            icon="$success"
            v-if="show"
            :text="text || 'Your record has been saved successfully.'">
        </v-alert>
    </transition>
</template>
<style scoped>

</style>