//customSvgs.ts
import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import SwitchesIcon from "../components/icons/SwitchesIcon.vue";

const customSvgNameToComponent: any = {
    'switches-icon': SwitchesIcon,
};

const customSVGs: IconSet = {
  component: (props: IconProps) => h(customSvgNameToComponent[props.icon as string]),
};

export { customSVGs /* aliases */ };
