<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps({
    show: Boolean,
    error: null
})

const getError: any = () => {
    let e = props.error && props.error.error ? props.error.error : props.error
    return e || {}
}

const errorMessage = computed(() => {
    let e = getError()
    if (e.toLowerCase) {
        return props.error
    }else if (e.message) {
        return e.message
    } else if (e.errors) {
        var messages = []
        for (const key in e.errors) {
            messages.push(key + ': ' + e.errors[key].join('\n'))
        }
        return messages.join('\n')
    } else {
        console.error('ErrorAlert: Unexpected error object', props.error)
        return 'An unexpected error occurred.'
    }
})
</script>
<template>
    <v-alert 
        type="error" 
        icon="$error"
        class="multi-line"
        :closable="true"
        v-if="show"
        :text="errorMessage"
        >
    </v-alert>
</template>
<style>
.multi-line {
  white-space: pre-line;
}
</style>